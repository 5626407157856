<template>
  <div>
    <!--TITULOS  -->
    <div class="air__utils__heading"> <h5>Master de Reactivos</h5> </div>
    <my-breadcrumbs :routes="routes"/>
    <!--TITULOS  -->
    <!--BOTONERA  -->
    <div class="row justify-content-end mr-2">
      <b-button-group>
        <b-button  variant="primary" @click="OpenModal()" pill>
          <div class="d-none d-md-block" v-b-tooltip.hover title="Nuevo">
            <b-icon icon="plus"/> Nuevo
          </div>
          <div class="d-md-none" v-b-tooltip.hover title="Nuevo">
            <b-icon icon="plus"/>
          </div>
        </b-button>
      </b-button-group>
    </div>
    <!--BOTONERA  -->
    <!--BARRA DE BUSQUEDA  -->
    <searchingBar :components="['filter', 'pages']"
          :sortByOptions="sortByOptions"
          @Fectch="FetchTable"
          @filterValue="filterValue = $event"
          @sortValue="tableObj.sortValue = $event"
          @pageSize="tableObj.pagination.pageSize = $event"
          @currentPageValue="tableObj.currentPage = $event"/>
    <!--BARRA DE BUSQUEDA  -->
    <div class="row" style="margin-left:1px">
      <div class="col-sm-12 col-md-6 col-lg-3" >
        <b-form-group id="plant-group" label="Planta" label-for="plant">
          <a-select v-model="filterPlant" allowClear @change="FetchTable()" style="width: 100%"
                  placeholder="Seleccionar Planta" :filter-option="filterOption" show-search>
            <a-select-option  v-for="option in plantsCombo" :key="option.id" :value="option.id" >
              {{option.name}}
            </a-select-option>
          </a-select>
        </b-form-group>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3">
        <b-form-group id="family-group" label="Familia" label-for="family">
          <a-select v-model="filterFamily" @change="FetchTable()" allowClear
                    placeholder="Todas las Familias" style="width: 100%"
                    show-search :filter-option="filterOption">
            <a-select-option v-for="option in familiesCombo" :key="option.id"
                              :value="option.id">
              {{ option.name }}
            </a-select-option>
          </a-select>
        </b-form-group>
      </div>
    </div>
    <!--TABLA-->
    <a-table :columns="tableObj.columns"
        :data-source="tableObj.data"
        :pagination="tableObj.pagination"
        :loading="tableObj.loading"
        @change="FetchTable"
        :row-key="record => record.id" bordered>
      <!-- <template slot="detail" slot-scope="data">
        <a-descriptions :title="`Reactivo: ${data.code} - ${data.name}`"
                        bordered
                        size="small"
                        :column="{ sm: 1, md: 2, lg: 3}">
          <a-descriptions-item label="Familia:">
            <strong>{{data.families.name}}</strong>
          </a-descriptions-item>
          <a-descriptions-item label="Mínimo:">
            <strong>{{data.min}}  </strong>
          </a-descriptions-item>
          <a-descriptions-item label="Máximo:">
            <strong>{{data.max}}</strong>
          </a-descriptions-item>
          <a-descriptions-item label="Hoja de Seguridad">
          <b-icon icon="check" variant="success" font-scale="2" v-if="data.security_sheet != null && data.security_sheet.length > 0"/>
          <b-icon icon="x" variant="warning" font-scale="2" v-else/>
          </a-descriptions-item>
          <a-descriptions-item label="Especificación Técnica">
            <span class="font-weight-bold small">
              {{data.technical_specification}}
            </span>
          </a-descriptions-item>
        </a-descriptions>
      </template> -->
      <template slot="action" slot-scope="data">
        <b-button  variant="secondary" @click="OpenModal(data)" pill>
          <div class="d-none d--block" v-b-tooltip.hover title="Editar">
            <b-icon icon="pencil-square"/> Editar
          </div>
          <div class="d--none" v-b-tooltip.hover title="Editar">
            <b-icon icon="pencil-square"/>
          </div>
        </b-button>
      </template>
      <template slot="nombreReactivo" slot-scope="record">
        <div class="row text-center">
          <div class="col">
            <div class="h5" style="color:black">
              {{`Reactivo: ${record.code} - ${record.name}`}}
            </div>
          </div>
        </div>
        <!-- <hr class="bg-primary"> -->
        <!-- <div class="row">
          <div class="col">
            <span class="font-weight-bold">
              {{ record.families.name }}
            </span>
          </div>
          <a-divider type="vertical" />
          <div class="col">
            <span class="font-weight-bold">
              {{ record.min }}
            </span>
          </div>
          <a-divider type="vertical" />
          <div class="col">
            <span class="font-weight-bold">
              {{ record.max }}
            </span>
          </div>
        </div> -->
      </template>
      <template slot="planta" slot-scope="record">
        <div class="row text-center medium">
          <div class="col">
            <div>
              {{`${record.work_centers.code} - ${record.work_centers.name}`}}
            </div>
          </div>
        </div>
      </template>
      <template slot="family" slot-scope="record">
        <div class="row text-center medium">
          <div class="col">
            <div>
              {{`${ (familiesCombo.find(e => e.id === record.families.id)) ? familiesCombo.find(e => e.id === record.families.id).name : '' }`}}
            </div>
          </div>
        </div>
      </template>
      <template slot="detallesReactivo" slot-scope="record">
        <div class="text-center">
          <small>
            <strong>
              Máximo:
            </strong>
          </small>
          <small>
            {{ record.max }}
          </small>
        </div>
        <div class="text-center">
          <small>
            <strong>
              Mínimo:
            </strong>
          </small>
          <small>
            {{ record.min }}
          </small>
        </div>
        <hr class="bg-primary">
        <div class="text-center small">
          {{'Hoja de Seguridad:  '}}
            <b-icon icon="check" variant="success" font-scale="2" v-if="record.security_sheet != null && record.security_sheet.length > 0"/>
            <b-icon icon="x" variant="warning" font-scale="2" v-else/>
        </div>
        <br>
        <div class="text-center small">
            {{'Especificación Técnica:  '}}
          <span class="font-weight-bold">
            {{record.technical_specification}}
          </span>
        </div>
      </template>
    </a-table>
    <template slot="familiaReactivo" slot-scope="record">
      <span class="font-weight-bold small">
        {{ record.families.id }}
      </span>
    </template>
    <!--TABLA-->
    <!-- MODAL -->
    <b-modal title="Detalle del reactivo" ref="modal-Form" size="lg" @hide="CloseModal">
      <my-spinner :load="spinnerLoad"/>
      <b-container fluid v-show="!spinnerLoad">
        <!--FORMULARIO-->
        <a-form-model :model="resourceObj.form" :rules="formRules" ref="ruleForm"
                      layout="vertical" :wrapper-col="{ span: 24}">
          <!--TEXT - Clave Nombre del reactivo-->
          <a-form-model-item label="Clave del reactivo" ref="name"  prop="name">
            <a-input v-model.trim="resourceObj.form.code" placeholder="Clave del reactivo" size="large" />
          </a-form-model-item>
          <!--TEXT -Clave Nombre del reactivo -->
          <!--TEXT - Nombre Nombre del reactivo-->
          <a-form-model-item label="Nombre del reactivo" ref="name"  prop="name" >
            <a-input v-model.trim="resourceObj.form.name" placeholder="Nombre del reactivo" size="large"/>
          </a-form-model-item>
          <!--TEXT -Nombre Nombre del reactivo -->
          <a-form-model-item label="Planta" ref="work_center_id"  prop="work_center_id" >
            <a-select v-model="resourceObj.form.work_center_id"
                      placeholder="Todas las plantas"
                      show-search :filter-option="filterOption">
              <a-select-option  v-for="option in plantsCombo" :key="option.id" :value="option.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!--SELECT - Familia -->
          <a-form-model-item label="Familia" ref="family_id" prop="family_id" >
            <a-select v-model="resourceObj.form.family_id"
                      placeholder="Todas las familias"
                      show-search :filter-option="filterOption">
              <a-select-option  v-for="option in familiesCombo" :key="option.id" :value="option.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!--SELECT - Familia -->
          <a-row align="middle" justify="center">
            <a-col :sm="{ span: 24 }" :md="{ span:12}">
              <!--NUMBER INPUT - Míinimo-->
              <a-form-model-item label="Mínimo" ref="min"  prop="min" >
                <a-input-number v-model.trim="resourceObj.form.min" :min="0" :max="100" :step="1" size="large" style="width: 100%"/>
              </a-form-model-item>
              <!--NUMBER INPUT -Míinimo -->
            </a-col>
            <a-col :sm="{ span: 24 }" :md="{ span:12}">
              <!--NUMBER INPUT - Máximo-->
              <a-form-model-item label="Máximo" ref="max"  prop="max" >
                <a-input-number v-model.trim="resourceObj.form.max" :min="0" :max="1000" :step="1" size="large" style="width: 100%"/>
              </a-form-model-item>
              <!--NUMBER INPUT -Máximo -->
            </a-col>
          </a-row>
          <!--TEXT-AREA - Especificacion tecnica-->
          <a-form-model-item label="Especificación técnica" ref="technical_specification"  prop="technical_specification" >
            <a-textarea v-model.trim="resourceObj.form.technical_specification" :auto-size="{ minRows: 4 }"/>
          </a-form-model-item>
          <!--TEXT-AREA -Especificacion tecnica -->
          <!--Dropzone - securitySheetArray-->
          <a-form-model-item  label="Hoja de seguridad" ref="securitySheetArray" prop="securitySheetArray" v-if="resourceObj.form.id">
            <div class="clearfix">
              <a-upload accept="image/*,.pdf" name="file"
                        :data="{'id': resourceObj.form.id, strategy:'ReagentSecuritySheet'}"
                        :action="attachFileUrl"
                        list-type="picture-card"
                        :file-list="resourceObj.form.securitySheetArray"
                        :before-upload="BeforeUpload"
                        @preview="handlePreview"
                        @change="UploadPhotos"
                        :remove="RemovePhoto"
              >
                <div>
                  <b-icon icon="plus"/>
                  <div class="ant-upload-text">Hoja de seguridad</div>
                </div>
              </a-upload>
            </div>
          </a-form-model-item>
          <fadeAlert text="La imagen cargada es ,mayor a 2MB" variant="danger" v-model="fadeAlter"/>
          <!--Dropzone - securitySheetArray-->
          <hr class="bg-primary">
          <!--BOTONES -->
          <div class="row justify-content-center">
            <b-button-group>
              <b-button  variant="primary" @click="Submit" pill>
                <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                  <b-icon icon="archive"/> Guardar
                </div>
                <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                  <b-icon icon="archive"/>
                </div>
              </b-button>
              <b-button v-if="resourceObj.form.id"  variant="danger" @click="Delete" pill>
                <div class="d-none d-md-block" v-b-tooltip.hover title="Eliminar">
                  <b-icon icon="trash"/> Eliminar
                </div>
                <div class="d-md-none" v-b-tooltip.hover title="Eliminar">
                  <b-icon icon="trash"/>
                </div>
              </b-button>
            </b-button-group>
          </div>
          <!--BOTONES -->
        </a-form-model>
        <!--FORMULARIO-->
      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- MODAL -->
    <!-- MODAL para vista previa de imagen -->
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false" :zIndex="3000">
      <embed v-if="previewImage.indexOf('pdf') !== -1"
             :src="previewImage" width="100%" height="100%" type="application/pdf" >
      <img v-else alt="previewFile" style="width: 100%;" :src="previewImage" />
    </a-modal>
    <!-- MODAL para vista previa de imagen -->
  </div>
</template>
<script>
import { fractalMixin } from '@/mixins/fractalMixin'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  name: 'reagentsMasterPage',
  mixins: [fractalMixin],
  data() {
    return {
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Laboratorios',
          aIcon: '',
          name: 'laboratories',
        },
        {
          breadcrumbName: 'Master de Reactivos',
          aIcon: '',
        },
      ],
      filterPlant: undefined,
      filterFamily: undefined,
      filterValue: '',
      sortByOptions: [],
      tableObj: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Nombre Reactivo',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'nombreReactivo' },
            width: '23%',
            // width: '85%',
            // children: [
            //   {
            //     // title: 'Nombre Reactivo',
            //     dataIndex: '',
            //     key: 'nombreReactivo',
            //     scopedSlots: { customRender: 'nombreReactivo' },
            //     class: 'text-center',
            //     // children: [
            //     //   {
            //     //     // title: 'Planta',
            //     //     dataIndex: 'families.name',
            //     //     key: 'families.name',
            //     //     class: 'text-center',
            //     //   },
            //     //   // {
            //     //   //   title: 'Familia',
            //     //   // },
            //     //   // {
            //     //   //   title: 'Límites',
            //     //   // },
            //     //   // {
            //     //   //   title: 'Especificaciones',
            //     //   // },
            //     // ],
            //   },
            // ],
          },
          {
            title: 'Planta',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'planta' },
            width: '11%',
          },
          {
            title: 'Familia',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'family' },
            width: '14%',
          },
          {
            title: 'Detalles',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'detallesReactivo' },
            width: '44%',
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'small text-center',
            scopedSlots: { customRender: 'action' },
            // fixed: 'right',
            width: '15%',
          },
        ],
      },
      resourceObj: {
        resourceType: 'reagents',
        form: {
          id: '',
          family_id: undefined,
          work_center_id: undefined,
          code: '',
          name: '',
          technical_specification: '',
          min: 0,
          max: 0,
          securitySheetArray: [],
        },
        // relationships: ['families', 'plants'],
      },
      formRules: {
        name: [{ required: true, message: 'El campo Clave del reactivo es obligatorio.', trigger: 'blur' }],
        code: [{ required: true, message: 'El campo Nombre del reactivo es obligatorio.', trigger: 'blur' }],
        family_id: [{ required: true, message: 'El campo Familia del reactivo es obligatorio.', trigger: 'blur' }],
        work_center_id: [{ required: true, message: 'El campo Planta del reactivo es obligatorio.', trigger: 'blur' }],
      },
      // Combos de formulario
      familiesCombo: [],
      plantsCombo: [],
      // Variables de flujo
      spinnerLoad: false,
      attachFileUrl: `${process.env.VUE_APP_API_URL}/attachFile`,
      previewVisible: false,
      previewImage: '',
      fadeAlter: false,
    }
  },
  methods: {
    async FetchCombos() {
      // const params = {
      //   'filter[family_type]': 'Reactivos',
      //   sort: 'name',
      // }
      // this.familiesCombo = await this.GetResource('/families', params)
      // const params1 = {
      //   sort: 'name',
      // }
      // this.plantsCombo = await this.GetResource('/workCenters', params1)
      const params1 = {
        orderField: 'name',
        orderType: 'asc',
      }
      const arr = [
        {
          path: '/fetch/workCenters',
          params: params1,
        },
        {
          path: '/fetch/families?pageSize=1000',
          params: params1,
        },
      ]
      const [plants, families] = await this.GetConcurrencyResources(arr)
      this.plantsCombo = plants
      this.familiesCombo = families
    },
    async FetchTable(pagEvent = {}) {
      // const params = {
      //   'page[number]': (Object.keys(pagEvent).length !== 0) ? `${this.tableObj.pagination.current}` : `${this.tableObj.currentPage}`,
      //   'page[size]': `${this.tableObj.pagination.pageSize}`,
      //   // sort: this.tableObj.sortValue,
      //   include: 'families,workCenters',

      // }
      const params = {
        page: (Object.keys(pagEvent).length !== 0) ? `${this.tableObj.pagination.current}` : `${this.tableObj.currentPage}`,
        pageSize: `${this.tableObj.pagination.pageSize}`,
        orderField: 'name',
        orderType: 'asc',
      }
      if (this.filterPlant !== '') params.workCenterId = this.filterPlant
      if (this.filterFamily !== '') params.familyId = this.filterFamily
      if (this.filterValue !== '') params.search = this.filterValue
      console.log('params', params)
      // await this.GetTableResource(pagEvent, this.resourceObj.resourceType, this.tableObj, params)
      await this.GetTableResourcev2(pagEvent, this.resourceObj.resourceType, this.tableObj, params)
    },
    OpenModal(data = {}) {
      this.$refs['modal-Form'].show()
      // Load combos
      if (Object.keys(data).length === 0) {
        //  Store case
        this.ResetForm()
      } else {
        // Update case
        this.resourceObj.form = {
          id: data.id.toString(),
          family_id: data.families.id,
          work_center_id: data.work_centers.id,
          code: data.code,
          name: data.name,
          technical_specification: data.technical_specification,
          min: data.min,
          max: data.max,
          securitySheetArray: [],
        }
        if (data.security_sheet !== null) this.resourceObj.form.securitySheetArray = data.security_sheet
      }
    },
    ResetForm() {
      this.resourceObj.form = {
        id: '',
        family_id: undefined,
        code: '',
        name: '',
        technical_specification: '',
        min: 0,
        max: 0,
        securitySheetArray: [],
      }
    },
    CloseModal() {
      this.$refs['modal-Form'].hide()
      this.ResetForm()
    },
    async Submit() {
      delete this.resourceObj.form.securitySheetArray
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          if (this.resourceObj.form.id === '') {
            delete this.resourceObj.form.id
            await this.PostResourcev2(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
          } else await this.PutResourcev2(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
        } else return false
      })
    },
    async Delete() {
      await this.deleteQuestion(this.resourceObj.resourceType, this.resourceObj.form.id, this.SuccessCallback)
    },
    async SuccessCallback() {
      this.spinnerLoad = false
      await this.FetchTable()
      this.CloseModal()
    },
    async UploadPhotos(info) {
      const { file } = info
      if (Object.hasOwnProperty.call(file, 'response') && Object.hasOwnProperty.call(file.response, 'files')) {
        this.resourceObj.form.securitySheetArray = file.response.files
        await this.FetchTable()
      }
    },
    BeforeUpload(file) {
      // Confirmacion de tamaño del archivo
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.fadeAlter = true
        return false
      } else {
        this.resourceObj.form.securitySheetArray.push({
          uid: file.uid,
          name: file.name,
          status: 'done',
          url: undefined,
        })
        return true
      }
    },
    async RemovePhoto(file) {
      const params = {
        id: this.resourceObj.form.id,
        url: file.url,
        strategy: 'ReagentSecuritySheet',
      }
      await this.DeleteFile('/dettachFile', params, async(resp) => {
        this.resourceObj.form.securitySheetArray = resp.files
        await this.FetchTable()
      })
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
  },
  async mounted() {
    await this.FetchCombos()
    await this.FetchTable()
  },
}
</script>
<style scoped></style>
